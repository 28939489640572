<script lang="ts" setup>
import { Ref, ref, watch } from 'vue';
import Loading from '@components/Loading.vue';
import ViewerImageFrame from './ViewerImageFrame.vue';
import { ViewerImage } from '@/types';
import { ImageSize } from '@viewModels/enums';
import { useMobileDetection } from '@utils/isMobile';

interface Props {
  viewName: string;
  leftImage: ViewerImage;
  rightImage?: ViewerImage | null;
  imageDate: string;
  maxScale?: number;
  opacity?: number;
  overlaid?: boolean;
  zoom?: {
    scale: number;
    x: number;
    y: number;
  };
}

const { isMobile } = useMobileDetection();

const isLoading = ref<boolean>(true);
const props = withDefaults(defineProps<Props>(), {
  maxScale: 50,
  opacity: 1,
  overlaid: false,
  rightImage: null,
  zoom: () => ({ scale: 1, x: 0, y: 0 }),
});

defineExpose({
  init,
  scaleTo,
  setTransform,
});

const frame = ref(null) as Ref<typeof ViewerImageFrame | null>;
const leftImageUrl = ref<string>();
const rightImageUrl = ref<string>();

watch(
  () => props.leftImage,
  () => {
    if (!props.leftImage.sourceURL) return;

    isLoading.value = true;
    const imageSize = isMobile ? ImageSize.Medium : ImageSize.Large;
    leftImageUrl.value = `${props.leftImage.sourceURL}${imageSize}`;
  },
  { immediate: true },
);

watch(
  () => props.rightImage,
  () => {
    if (!props.rightImage?.sourceURL) {
      rightImageUrl.value = undefined;
      return;
    }

    isLoading.value = true;
    const imageSize = isMobile ? ImageSize.Medium : ImageSize.Large;
    rightImageUrl.value = `${props.rightImage.sourceURL}${imageSize}`;
  },
  { immediate: true },
);

function init(): void {
  if (frame.value) frame.value['init']();
}

function scaleTo(scale: number, options: any): void {
  if (frame.value) frame.value['scaleTo'](scale, options);
}

function setTransform(zoom: any): void {
  if (frame.value) frame.value['setTransform'](zoom);
}
</script>

<template>
  <div :id="'frame' + leftImage.id" class="viewer__frame">
    <ViewerImageFrame v-if="leftImage.sourceURL"
                      ref="frame"
                      :left-image-src="leftImageUrl!"
                      :max-scale="maxScale"
                      :right-image-src="rightImageUrl"
                      @image-loaded="isLoading = false" />

    <slot name="viewer-frame-buttons" />

    <div v-if="!overlaid"
         class="viewer__frame--date">
      <p>{{ imageDate }}</p>
    </div>

    <div v-if="!overlaid"
         class="viewer__frame--name">
      <p>{{ viewName }}</p>
    </div>

    <Loading v-if="isLoading" :is-absolute-positioned="true" />
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.viewer__frame {
  overflow: hidden;
  line-height: 0;
  background: $neutral-300;
  border-radius: 10px;

  &--date {
    position: absolute;
    right: 15px;
    bottom: 15px;
    padding: 8px 10px;
    margin: 0;
    font-size: 0.8rem;
    line-height: 1;
    color: $neutral-50;
    background: $black-opacity-75;
    border-radius: 5px;

    p {
      margin: 0;
    }
  }

  &--name {
    position: absolute;
    bottom: 15px;
    left: 15px;
    display: none;
    padding: 8px 10px;
    margin: 0;
    font-size: 0.8rem;
    line-height: 1;
    color: $neutral-50;
    background: $black-opacity-75;
    border-radius: 5px;

    @media screen and (min-width: $breakpoint-lg) {
      display: block;
    }

    p {
      margin: 0;
    }
  }
}
</style>

import type { Router } from 'vue-router';

const EXCLUDE_PATHS = ['/login'];

/**
 * Checks if a given URL is valid.
 *
 * @param {string} url - The URL to be validated.
 * @returns {boolean} True if the URL is valid, false otherwise.
 */
const isValidUrl = (url: string): boolean => {
  try {
    // Attempt to create a new URL object from the given string.
    new URL(url);
    return true;
  } catch {
    return false;
  }
};

/**
 * Retrieves the continue URL from the current route.
 *
 * If a 'continueUrl' query parameter is present, it will be validated and its pathname returned.
 * Otherwise, the current path will be returned, unless it is excluded, in which case '/views' will be returned.
 *
 * @param {Router} router - The Vue Router instance.
 * @returns {string | undefined} The continue URL or undefined if it is invalid.
 */
export const getContinueUrl = (router: Router): string | undefined => {
  // Get the 'continueUrl' query parameter from the current route.
  const continueUrl = router.currentRoute.value.query['continueUrl'];

  if (continueUrl) {
    // Convert the query parameter to a string.
    const continueUrlString = String(continueUrl);

    // Validate the continue URL.
    if (isValidUrl(continueUrlString)) {
      // If the URL is valid, return its pathname.
      return new URL(continueUrlString).pathname;
    } else {
      console.warn(`Invalid continueUrl: ${continueUrlString}`);
      return undefined;
    }
  }

  // Get the current path from the router.
  const currentPath = router.currentRoute.value.path;

  // Check if the current path is excluded.
  return EXCLUDE_PATHS.includes(currentPath) ? '/views' : currentPath;
};
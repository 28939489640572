import { defineStore } from 'pinia';
import * as client from '@gabrielcam/api-client';
import { ViewerImage } from '@/types';
import { dateFormat, dateTimeFormat, timeFormat } from '@utils/date';
import moment from 'moment';
import { useApplicationStore } from '@stores/application';

export enum VIEW_MODE {
    SINGLE = 'single',
    COMPARE = 'compare',
    OVERLAY = 'overlay'
}

export enum COMPARE_MODE_FOCUS {
    LEFT = 'left',
    RIGHT = 'right',
}

interface State {
    viewId: string | null,
    images: ViewerImage[],
    loadingImages: boolean,
    selectedImage: ViewerImage | null,
    capturedDates: string[],
    selectedDate: string | null,
    viewMode: VIEW_MODE,
    viewModeCompareSettings: {
        focusedImage: COMPARE_MODE_FOCUS,
        leftImage: ViewerImage | null
        rightImage: ViewerImage | null,
    },
    viewModeOverlaySettings: {
        primaryImage: ViewerImage | null,
        overlayImage: ViewerImage | null,
    }
}

export const useIrisViewerStore = defineStore('irisViewerStore', {
    state: (): State => ({
        viewId: null,
        images: [],
        loadingImages: false,
        selectedImage: null,
        capturedDates: [],
        selectedDate: null,
        viewMode: VIEW_MODE.SINGLE,
        viewModeCompareSettings: {
            focusedImage: COMPARE_MODE_FOCUS.LEFT,
            leftImage: null,
            rightImage: null,
        },
        viewModeOverlaySettings: {
            primaryImage: null,
            overlayImage: null,
        }
    }),

    getters: {
        isCompareMode: (state) => state.viewMode === VIEW_MODE.COMPARE,

        isSingleMode: (state) => state.viewMode === VIEW_MODE.SINGLE,

        isOverlayMode: (state) => state.viewMode === VIEW_MODE.OVERLAY,

        hasImages: (state) => state.images.length > 0,

        hasPreviousImage: (state) => {
            const currentIndex = state.images.findIndex((image: ViewerImage) => image.id === state.selectedImage!.id)
            return !!state.images[currentIndex+1];
        },

        hasNextImage: (state) => {
            const currentIndex = state.images.findIndex((image: ViewerImage) => image.id === state.selectedImage!.id)
            return !!state.images[currentIndex-1];
        },

        getLeftSelectedImage: (state) => state.viewModeCompareSettings.leftImage ?? state.selectedImage,

        getRightSelectedImage: (state) => state.viewModeCompareSettings.rightImage,
    },

    actions: {
        async init(viewId: string) {
            this.viewId = viewId;
            try {
                this.capturedDates = (await client.listViewByIdCapturedDates({ viewId: viewId })).data;
                if (this.capturedDates) {
                    await this.setSelectedDate(this.capturedDates[this.capturedDates.length - 1]!);
                    this.setSelectedImage(this.images[0]!);
                }
            } catch (error) {
                console.error('Failed to init:', error);
            }
        },

        async fetchImagesForDate(date: string) {
            const applicationStore = useApplicationStore();
            const { activeUser } = applicationStore;
            this.loadingImages = true;
            try {
                const startDate = new Date(date).toISOString();
                const endDate = new Date(new Date(date).setHours(23, 59, 29, 999)).toISOString();
                this.images = (await client.listViewByIdImages({
                    viewId: this.viewId!,
                    status: client.ImageStatus.COMPLETE,
                    startDate: startDate,
                    endDate: endDate,
                })).data.filter(
                    (x) => x.capturedAt !== undefined
                ).map((image: ViewerImage) => {
                    image.formattedDateTime = dateTimeFormat(activeUser?.timezone).format(new Date(image.capturedAt!));
                    image.formattedTime = timeFormat(activeUser?.timezone).format(new Date(image.capturedAt!));
                    image.formattedDate = dateFormat(activeUser?.timezone).format(new Date(image.capturedAt!));
                    const localTime = moment(image.capturedAt).local();
                    image.capturedAt = localTime.format('YYYY-MM-DD HH:mm:ss');

                    return image
                });
            } catch (error) {
                console.error('Failed to fetch images:', error);
            }
            this.loadingImages = false;
        },

        resetViewMode() {
            this.viewMode = VIEW_MODE.SINGLE;
            this.viewModeCompareSettings.focusedImage = COMPARE_MODE_FOCUS.LEFT;
            this.viewModeCompareSettings.leftImage = null;
            this.viewModeCompareSettings.rightImage = null;
        },

        setSelectedImage(image: ViewerImage) {
            this.selectedImage = image;

            if (this.isCompareMode) {
                if (this.viewModeCompareSettings.focusedImage === COMPARE_MODE_FOCUS.LEFT) {
                    this.viewModeCompareSettings.leftImage = image;
                } else {
                    this.viewModeCompareSettings.rightImage = image;
                }
            }
        },

        async setSelectedDate(date: string) {
            this.selectedDate = date;
            await this.fetchImagesForDate(this.selectedDate)
        },

        async setSelectedImageToFirst() {
            await this.setSelectedDate(this.capturedDates[0]!);
            this.setSelectedImage(this.images[this.images.length - 1]!);
        },

        async setSelectedImageToLast() {
            await this.setSelectedDate(this.capturedDates[this.capturedDates.length - 1]!);
            this.setSelectedImage(this.images[0]!);
        },

        setSelectedImageToPrevious() {
            const currentIndex = this.images.findIndex((image: ViewerImage) => image.id === this.selectedImage!.id)
            this.setSelectedImage(this.images[currentIndex+1]!);
        },

        setSelectedImageToNext() {
            const currentIndex = this.images.findIndex((image: ViewerImage) => image.id === this.selectedImage!.id)
            this.setSelectedImage(this.images[currentIndex-1]!);
        },

        startCompareMode() {
            this.viewMode = VIEW_MODE.COMPARE;
            this.viewModeCompareSettings.leftImage = this.selectedImage;
            this.viewModeCompareSettings.rightImage = this.selectedImage;
        },

        setCompareModeFocus(focus: COMPARE_MODE_FOCUS) {
            this.viewModeCompareSettings.focusedImage = focus;

            if (focus === COMPARE_MODE_FOCUS.LEFT) {
                this.selectedImage = this.viewModeCompareSettings.leftImage!
            } else {
                this.selectedImage = this.viewModeCompareSettings.rightImage!
            }
        }

    },
});
